<script lang="ts" setup>
import type { homeInterlocutors } from '~/interfaces/home.interface'

onMounted(() => {
  const appear = document.querySelectorAll('.appear')
  appear.forEach((appearItem) => {
    const rectOnLoad = appearItem.getBoundingClientRect()
    const visibleOnLoad = rectOnLoad.top <= window.innerHeight
    if (visibleOnLoad) appearItem.classList.add('active')

    window.addEventListener('scroll', () => {
      const rect = appearItem.getBoundingClientRect()
      const visible = rect.top <= (window.innerHeight / 10) * 9
      if (visible) appearItem.classList.add('active')
    })
  })
  const focus = document.querySelectorAll('.focus')
  focus.forEach((focusItem) => {
    const rectOnLoad = focusItem.getBoundingClientRect()
    const visibleOnLoad = rectOnLoad.top <= window.innerHeight
    if (visibleOnLoad) focusItem.classList.add('active')

    window.addEventListener('scroll', () => {
      const rect = focusItem.getBoundingClientRect()
      const visible = rect.top <= (window.innerHeight / 10) * 9
      if (visible) focusItem.classList.add('active')
    })
  })
})

defineProps<{
  content?: homeInterlocutors
}>()
</script>

<template>
  <section v-if="content" id="interlocutors" class="mt-50">
    <UContainer>
      <p
        v-if="content.text"
        class="text-primary appear mb-40 text-p2 duration-300"
      >
        {{ content.text }}
      </p>
      <div class="grid grid-cols-2 gap-24 md:grid-cols-3 lg:grid-cols-5">

        <div
          v-for="interlocutor in content.interlocutors"
          :key="interlocutor.id"
        >
          <div class="focus duration-300 max-md:aspect-[1/1] md:h-[340px] mb-24">
            <AkImage
              v-if="interlocutor.image.data"
              class="rounded-10 object-cover h-full w-full"
              :image="interlocutor.image"
              transform="contain=HxH/crop=WxH/quality=100"
            />
          </div>
          <h2 class="text-h4 appear font-semibold duration-300">
            {{ interlocutor.name }}
          </h2>
          <h3 class="appear text-p2 text-gray-500 duration-300">
            {{ interlocutor.informations }}
          </h3>
        </div>
      </div>
    </UContainer>
  </section>
</template>

<style lang="scss" scoped>
.focus {
  transform: scale(1.1);
  opacity: 0;
  &.active {
    transform: none;
    opacity: 1;
  }
}
.appear {
  opacity: 0;
  transform: translateY(60px);
  &.active {
    transform: none;
    opacity: 1;
  }
}
</style>
