<script lang="ts" setup>
import type { homeZigzag } from '~/interfaces/home.interface'

const target = ref([])

onMounted(() => {
  target.value.forEach((item) => {
    window.addEventListener('scroll', () => {
      const rect = item.getBoundingClientRect()
      const visible =
        rect.top >= 0 && rect.bottom <= (window.innerHeight / 6) * 5
      if (visible) item.classList.add('active')
    })
  })
  const focus = document.querySelectorAll('.focus')
  focus.forEach((focusItem) => {
    window.addEventListener('scroll', () => {
      const rect = focusItem.getBoundingClientRect()
      const visible =
        rect.top <= (window.innerHeight / 10) * 9
      if (visible) focusItem.classList.add('active')
    })
  })
})

const props = defineProps<{
  content?: homeZigzag
}>()

const splitTitles = ref({})

props.content.items.forEach((item) => {
  if (item.title) {
    const split = item.title.split(' ')
    splitTitles.value[item.id] = split
  }
})
</script>

<template>
  <section v-if="content" class="zigzag">
    <UContainer>
      <div v-for="zigzag in content.items" :key="zigzag.id" class="mt-112">
        <AkRow :reverse-lg="zigzag.position === 'gauche'" align="center">
          <AkCol :cols="12" :cols-lg="6">
            <div class="lg:flex lg:h-full lg:flex-col">
              <h5 v-if="zigzag.on_title" class="text-primary text-h5 mb-24">
                {{ zigzag.on_title }}
              </h5>
              <h4
                v-if="zigzag.title"
                class="text-primary text-h3 font-semibold"
              >
                <span
                  v-for="title in splitTitles[zigzag.id]"
                  ref="target"
                  class="title inline-block"
                  >{{ title }}&nbsp;
                </span>
              </h4>
              <div class="my-24 h-2 w-62 bg-secondary" />
              <AkText
                v-if="zigzag.text"
                class="text-primary mb-24 text-p1"
                :text="zigzag.text"
              />
              <AkButton
                v-if="zigzag.button"
                class="mt-auto"
                block
                :button="zigzag.button"
              />
            </div>
          </AkCol>
          <AkCol :cols="12" :cols-lg="6">
            <div class="focus duration-300">
              <AkImage
                :align="zigzag.position === 'gauche' ? 'start' : 'end'"
                class="h-full rounded-10 object-cover"
                :image="zigzag.image"
              />
            </div>
          </AkCol>
        </AkRow>
      </div>
    </UContainer>
  </section>
</template>

<style lang="scss" scoped>
.title {
  color: #ccc;

  &.active {
    @apply text-primary;
  }
}
.focus {
  transform: scale(1.1);
  opacity: 0;
  &.active {
    transform: none;
    opacity: 1;
  }
}
</style>
